import { CssBaseline } from "@material-ui/core";
import { AccessProvider } from "./access/access.reducer";
import { DatasetProvider } from "./dataset/dataset.reducer";
import { ParamProvider } from "./parameter/parameter.reducer";
import ThemeWrapper from "./ThemeWrapper";
import { UploadProvider } from "./upload/upload.reducer";
import { AuthProvider } from "./user/user.reducer";
import { UserDataProvider } from "./userData/userData.reducer";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

const RootProvider = ({ children }) => (
  <AuthProvider>
    <UserDataProvider>
      <AccessProvider>
        <ParamProvider>
          <DatasetProvider>
            <UploadProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeWrapper>
                  <CssBaseline />
                  {children}
                </ThemeWrapper>
              </MuiPickersUtilsProvider>
            </UploadProvider>
          </DatasetProvider>
        </ParamProvider>
      </AccessProvider>
    </UserDataProvider>
  </AuthProvider>
);

export default RootProvider;
