const UploadActionTypes = {
  UPLOAD_START: "UPLOAD_START",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "UPLOAD_FAILURE",
  RESTART_UPLOAD: "RESTART_UPLOAD",
  FILE_UPLOAD_ERROR: "FILE_UPLOAD_ERROR",
  RESET_UPLOAD_ERROR: "RESET_UPLOAD_ERROR",
};

export default UploadActionTypes;
