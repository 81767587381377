import { useContext, useState } from "react";
import Container from "@material-ui/core/Container";
import CustomTextField from "../CustomTextField";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { Box, useMediaQuery } from "@material-ui/core";
import { authContext } from "../../context/user/user.reducer";
import { signInStartAsync, signOut } from "../../context/user/user.actions";
import CustomButton from "../CustomButton";

const AuthPage = () => {
  const { state, dispatch } = useContext(authContext);
  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const { email, password } = userCredentials;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleLogin = async (event) => {
    event.preventDefault();
    dispatch(signInStartAsync(email, password));
    setCredentials({ email: "", password: "" });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  // snackbar
  const handleClose = () => {
    // resetting error
    dispatch(signOut());
  };

  return (
    <>
      <Container maxWidth={"sm"}>
        <Paper>
          <form onSubmit={handleLogin}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="300px"
              margin="auto"
              p={3}
              marginTop={"200px"}
            >
              <Box p={2}>
                <CustomTextField
                  label="Email Address"
                  type="email"
                  value={email ?? ""}
                  name="email"
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box p={2}>
                <CustomTextField
                  label="Password"
                  type="password"
                  value={password ?? ""}
                  name="password"
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box m={2}>
                <CustomButton type="submit">LOG IN</CustomButton>
              </Box>
            </Box>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: isMobile ? "center" : "right",
          }}
          open={!!state.error}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Box style={{ backgroundColor: "lightgoldenrodyellow", padding: 20 }}>
            Invalid Email Address or Password
            {/* {state.error} */}
          </Box>
        </Snackbar>
      </Container>
    </>
  );
};

export default AuthPage;
