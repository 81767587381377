export const updateParamData = (allParams, updateParam) => {
  let updatedAllParams = [...allParams];
  let targetParamIdx = updatedAllParams.findIndex(
    (param) => param.id === updateParam.id
  );
  updatedAllParams[targetParamIdx] = updateParam;

  return updatedAllParams;
};

export const findParamFromTagIds = (tagArray, allParams) => {
  if (allParams === null || allParams.length === 0 || tagArray === []) {
    return [];
  }
  const selectedParams = allParams.filter(
    (paramObj) => tagArray.indexOf(paramObj.tagId) > -1
  );
  return selectedParams;
};

export const getTagNameFromId = (tagId, allTags) => {
  if (!tagId || !allTags) {
    return "Name not found";
  }

  const tagNameIdx = allTags.findIndex((tagObj) => tagObj.id === tagId);

  return allTags[tagNameIdx].name;
};
