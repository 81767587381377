import { apiUrl } from "../../util";
import ParamActionTypes from "./parameter.types";

export const fetchParamStart = () => ({
  type: ParamActionTypes.FETCH_PARAMETERS_START,
});

export const fetchParamFailure = (error) => ({
  type: ParamActionTypes.FETCH_PARAMETERS_FAILURE,
  payload: error,
});

export const fetchParamSuccess = (params) => ({
  type: ParamActionTypes.FETCH_PARAMETERS_SUCCESS,
  payload: params,
});

export const fetchParamStartAsync = (token) => {
  return async (dispatch) => {
    dispatch(fetchParamStart());
    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "GET",
      headers: currentHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/parameters/getparameters`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(fetchParamSuccess(data));
        });
      } else {
        dispatch(fetchParamFailure("Could not fetch Param data"));
      }
    } catch (error) {
      dispatch(fetchParamFailure("Could not fetch Param data"));
    }
  };
};

export const fetchTagStart = () => ({
  type: ParamActionTypes.FETCH_TAGS_START,
});

export const fetchTagFailure = (error) => ({
  type: ParamActionTypes.FETCH_TAGS_FAILURE,
  payload: error,
});

export const fetchTagSuccess = (tagData) => ({
  type: ParamActionTypes.FETCH_TAGS_SUCCESS,
  payload: tagData,
});

export const fetchTagStartAsync = (token) => {
  return async (dispatch) => {
    dispatch(fetchTagStart());
    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "GET",
      headers: currentHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/parameters/gettags`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(fetchTagSuccess(data));
        });
      } else {
        dispatch(fetchTagFailure("Could not fetch Access data"));
      }
    } catch (error) {
      dispatch(fetchTagFailure("Could not fetch Access data"));
    }
  };
};

export const createParamStart = () => ({
  type: ParamActionTypes.CREATE_PARAMETER_START,
});

export const createParamFailure = (error) => ({
  type: ParamActionTypes.CREATE_PARAMETER_FAILURE,
  payload: error,
});

export const createParamSuccess = (newParam) => ({
  type: ParamActionTypes.CREATE_PARAMETER_SUCCESS,
  payload: newParam,
});

export const createParamStartAsync = (paramObj, token) => {
  return async (dispatch) => {
    dispatch(createParamStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "POST",
      headers: currentHeaders,
      body: JSON.stringify(paramObj),
    };

    try {
      const response = await fetch(
        `${apiUrl}/parameters/createparameter`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          // data: obj{id: string, name: string, [accessProps]: boolean}
          dispatch(createParamSuccess(data));
        });
      } else {
        dispatch(createParamFailure("Could not create new param"));
      }
    } catch (error) {
      dispatch(createParamFailure("Could not create new param"));
    }
  };
};

export const updateParamStart = () => ({
  type: ParamActionTypes.UPDATE_PARAMETER_START,
});

export const updateParamFailure = (error) => ({
  type: ParamActionTypes.UPDATE_PARAMETER_FAILURE,
  payload: error,
});

export const updateParamSuccess = (updatedUser) => ({
  type: ParamActionTypes.UPDATE_PARAMETER_SUCCESS,
  payload: updatedUser,
});

export const updateParamStartAsync = (paramObj, token) => {
  return async (dispatch) => {
    dispatch(updateParamStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "PUT",
      headers: currentHeaders,
      body: JSON.stringify(paramObj),
    };

    try {
      const response = await fetch(
        `${apiUrl}/parameters/updateparameter/:${paramObj.id}`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(updateParamSuccess(data));
        });
      } else {
        dispatch(updateParamFailure("Could not update param"));
      }
    } catch (error) {
      dispatch(updateParamFailure("Could not update param"));
    }
  };
};

export const toggleParameterDialog = () => ({
  type: ParamActionTypes.TOGGLE_PARAMETER_DIALOG
})

export const resetError = () => ({
  type: ParamActionTypes.RESET_ERROR,
});
