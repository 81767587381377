import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";

import AccessActionTypes from "./access.types";
import { updateAccessData } from "./access.utils";

const testdata = [
  {
    id: "1",
    name: "admin",
    uploaddata: true,
    viewdashboards: false,
    editusers: true,
    editaccess: true,
    editparameters: true,
    editimports: true,
    waterquality: false,
    sourcewater: true,
    plantprocess: false,
    clearwell: true,
    watershed: false,
    distribution: false,
  },
];

const ACCESS_INITIAL_STATE = {
  data: testdata, // array[{id: string?, name: string, [accessProps]: boolean}]
  // data: null,
  isFetching: false, // initial data fetch
  isSaving: false, //updating, creating
  error: null,
};

const accessReducer = (state = ACCESS_INITIAL_STATE, action) => {
  switch (action.type) {
    case AccessActionTypes.FETCH_ACCESS_START:
      return {
        ...state,
        isFetching: true,
      };
    case AccessActionTypes.FETCH_ACCESS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null,
      };
    case AccessActionTypes.FETCH_ACCESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case AccessActionTypes.CREATE_ACCESS_START:
    case AccessActionTypes.UPDATE_ACCESS_START:
      return {
        ...state,
        isSaving: true,
      };
    case AccessActionTypes.CREATE_ACCESS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        data: [...state.data,action.payload],
      };
    case AccessActionTypes.UPDATE_ACCESS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        data: updateAccessData(state.data, action.payload),
      };
    case AccessActionTypes.CREATE_ACCESS_FAILURE:
    case AccessActionTypes.UPDATE_ACCESS_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload,
      };
    case AccessActionTypes.RESET_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

const accessContext = createContext(ACCESS_INITIAL_STATE);

const { Provider } = accessContext;

const AccessProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    accessReducer,
    ACCESS_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AccessProvider, accessContext, accessReducer };
