import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CustomButton from "../CustomButton";
import CustomTextField from "../CustomTextField";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { authContext } from "../../context/user/user.reducer";
import {
  createParamStartAsync,
  updateParamStartAsync,
} from "../../context/parameter/parameter.actions";

const ParamDialogForm = ({ currentEdit, onClose, param, isMobile }) => {
  const [paramObj, setParamObj] = useState({
    name: "",
    tagId: "",
    units: "",
    dl: 0,
    aliases: [],
  });

  const [newAlias, setNewAlias] = useState("");
  const [isAliasDialogOpen, setIsAliasDialogOpen] = useState(false);

  const user = useContext(authContext);
  const token = user.state.currentUser.token;

  const { name, tagId, units, dl, aliases } = paramObj;
  const { state, dispatch } = param; //from parent paramContext

  useEffect(() => {
    if (currentEdit) {
      setParamObj({
        name: currentEdit.name,
        tagId: currentEdit.tagId,
        units: currentEdit.units,
        dl: currentEdit.dl,
        aliases: currentEdit.aliases,
      });
    }
  }, [currentEdit]);

  const handleDeleteAlias = (targetToDelete) => {
    setParamObj({
      ...paramObj,
      aliases: aliases.filter((alias) => alias !== targetToDelete),
    });
  };

  const handleCloseAliasDialog = () => {
    setIsAliasDialogOpen(!isAliasDialogOpen);
    setNewAlias("");
  };

  const handleAddAliasDialog = () => {
    if (aliases.includes(newAlias)) {
      return;
    }
    setParamObj({ ...paramObj, aliases: [...aliases, newAlias] });
    setIsAliasDialogOpen(!isAliasDialogOpen);
    setNewAlias("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (currentEdit) {
      const { id } = currentEdit;
      dispatch(
        updateParamStartAsync(
          {
            id,
            ...paramObj,
          },
          token
        )
      );
    } else {
      dispatch(createParamStartAsync(paramObj, token));
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setParamObj({ ...paramObj, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box p={3} minWidth={isMobile ? "75vw" : "420px"}>
        <Typography>
          {currentEdit ? "Edit Parameter" : "Create New Parameter"}
        </Typography>
        <Box p={2}>
          <CustomTextField
            label="Parameter"
            type="text"
            onChange={handleChange}
            value={name ?? ""}
            name="name"
            disabled={currentEdit}
            inputStyle={{ width: "100%" }}
          />
        </Box>
        <Box p={2}>
          <InputLabel id="select-Label">Tag</InputLabel>
          <Select
            labelId="select-Label"
            name="tagId"
            value={tagId}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value={""}>
              <em>None</em>
            </MenuItem>
            {state.allTags ? (
              state.allTags.map((tagObj) => (
                <MenuItem value={tagObj.id} key={tagObj.id}>
                  <em>{tagObj.name}</em>
                </MenuItem>
              ))
            ) : (
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
            )}
          </Select>
        </Box>
        <Box p={2}>
          <CustomTextField
            label="Default Units"
            type="text"
            value={units ?? ""}
            name="units"
            onChange={handleChange}
            inputStyle={{ width: "100%" }}
            required
          />
        </Box>
        <Box p={2}>
          <CustomTextField
            label="Default DL"
            type="number"
            value={dl}
            name="dl"
            onChange={handleChange}
            inputStyle={{ width: "100%" }}
            required
          />
        </Box>
        <Typography style={{ marginTop: "32px" }} display="inline">
          Aliases
        </Typography>
        <IconButton onClick={() => setIsAliasDialogOpen(!isAliasDialogOpen)}>
          <AddCircleIcon />
        </IconButton>
        <Dialog
          onClose={() => setIsAliasDialogOpen(!isAliasDialogOpen)}
          aria-labelledby="access-name-dialog"
          open={isAliasDialogOpen}
        >
          <Box p={2}>
            <Typography variant="h5">Create New Alias</Typography>
            <CustomTextField
              label={"New Alias"}
              type="text"
              value={newAlias}
              name="alias"
              onChange={(e) => setNewAlias(e.target.value)}
            />
            {aliases.includes(newAlias) ? (
              <Typography variant="caption">Alias Already exist</Typography>
            ) : null}
            <Box>
              <CustomButton invert onClick={handleCloseAliasDialog}>
                Cancel
              </CustomButton>
              <CustomButton invert onClick={handleAddAliasDialog}>
                Add
              </CustomButton>
            </Box>
          </Box>
        </Dialog>
        <Box minHeight="50px">
          {aliases.map((text) => (
            <Chip
              label={text}
              key={text}
              onDelete={() => handleDeleteAlias(text)}
            />
          ))}
        </Box>
        <Box p={2} display="flex">
          {currentEdit ? (
            <IconButton onClick={() => console.log("delete")}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <div style={{ flex: 1 }}></div>
          )}
          <CustomButton invert type="button" onClick={onClose}>
            Cancel
          </CustomButton>
          {state.isSaving ? (
            <CircularProgress />
          ) : (
            <CustomButton invert type="submit">
              {currentEdit? "Save" : "Create"}
            </CustomButton>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default ParamDialogForm;
