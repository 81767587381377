import { apiUrl } from "../../util";
import UploadActionTypes from "./upload.types";

export const uploadStart = () => ({
  type: UploadActionTypes.UPLOAD_START,
});

export const uploadFailure = (error) => ({
  type: UploadActionTypes.UPLOAD_FAILURE,
  payload: error,
});

export const uploadSuccess = () => ({
  type: UploadActionTypes.UPLOAD_SUCCESS,
});

export const uploadStartAsync = (token, dataPath, file) => {
  return async (dispatch) => {
    dispatch(uploadStart());
    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "POST",
      headers: currentHeaders,
      body: file,
    };

    let url = `${apiUrl}/upload/${dataPath}`;

    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(uploadSuccess());
        });
      } else {
        // replace with error from api
        dispatch(uploadFailure(["Could not upload the file"]));
      }
    } catch (error) {
      dispatch(uploadFailure(["Could not upload the file"]));
    }
  };
};

export const restartUpload = () => ({
  type: UploadActionTypes.RESTART_UPLOAD,
});

export const fileUploadError = (error) => ({
  type: UploadActionTypes.FILE_UPLOAD_ERROR,
  payload: error
})

export const resetUploadError = () => ({
  type: UploadActionTypes.RESET_UPLOAD_ERROR,
});
