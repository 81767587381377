import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {
  createUserStartAsync,
  updateUserStartAsync,
} from "../../context/userData/userData.actions";
import { userDataContext } from "../../context/userData/userData.reducer";
import CustomButton from "../CustomButton";
import CustomTextField from "../CustomTextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { authContext } from "../../context/user/user.reducer";
import { accessContext } from "../../context/access/access.reducer";
import { fetchAccessStartAsync } from "../../context/access/access.actions";

const UserDialogForm = ({ currentEdit, onClose, isMobile }) => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    name: "",
    accesslevelId: "",
    password: "",
  });

  const user = useContext(authContext);
  const token = user.state.currentUser.token;

  const { email, name, accesslevelId, password } = userInfo;

  const { state, dispatch } = useContext(userDataContext);
  const access = useContext(accessContext);

  useEffect(() => {
    if (currentEdit) {
      setUserInfo({
        email: currentEdit.email,
        name: currentEdit.name,
        accesslevelId: currentEdit.accesslevelId,
      });
    }
    if (!access.state.data) {
      access.dispatch(fetchAccessStartAsync(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEdit]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (currentEdit) {
      const { id } = currentEdit;
      dispatch(
        updateUserStartAsync(
          id,
          name,
          email,
          accesslevelId,
          user.state.currentUser.token
        )
      );
    } else {
      dispatch(
        createUserStartAsync(
          name,
          email,
          accesslevelId,
          user.state.currentUser.token
        )
      );
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Box p={3} minWidth={isMobile ? "75vw" : "420px"}>
          <Typography>
            {currentEdit ? "Edit User" : "Create New User"}
          </Typography>
          <Box p={1} marginTop="16px">
            <CustomTextField
              label="Email"
              type="email"
              onChange={handleChange}
              value={email}
              name="email"
              disabled={currentEdit}
              inputStyle={{ width: "100%" }}
            />
          </Box>
          <Box p={1}>
            <CustomTextField
              label="Display Name"
              type="text"
              value={name ?? ""}
              name="name"
              onChange={handleChange}
              inputStyle={{ width: "100%" }}
              required
            />
          </Box>
          {currentEdit ? null : (
            <Box p={1}>
              <CustomTextField
                label="Password"
                type="password"
                value={password}
                name="password"
                onChange={handleChange}
                inputStyle={{ width: "100%" }}
                required
              />
            </Box>
          )}

          <Box p={1} marginTop="16px">
            <InputLabel id="select-Label">Access Level</InputLabel>
            <Select
              labelId="select-Label"
              name="accesslevelId"
              value={accesslevelId}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              {access.state.data ? (
                access.state.data.map((accessObj) => (
                  <MenuItem value={accessObj.id} key={accessObj.id}>
                    <em>{accessObj.name}</em>
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </Box>
          <Box p={2} display="flex">
            {currentEdit ? (
              <IconButton
                onClick={() =>
                  dispatch(
                    updateUserStartAsync(
                      currentEdit.id,
                      name,
                      email,
                      accesslevelId,
                      user.state.currentUser.token,
                      false
                    )
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            ) : (
              <div style={{ flex: 1 }}></div>
            )}
            <CustomButton invert type="button" onClick={onClose}>
              Cancel
            </CustomButton>
            {state.isSaving ? (
              <CircularProgress />
            ) : (
              <CustomButton invert type="submit">
                Save
              </CustomButton>
            )}
          </Box>
        </Box>
      </Container>
    </form>
  );
};

export default UserDialogForm;
