import { Box, IconButton, Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useMemo, useRef, useState } from "react";
import DatasetTable from "./DatasetTable";
import { useTable } from "react-table";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import React from "react";

const DatasetContainer = React.forwardRef(
  ({ dataset, onClose, name, startdate, enddate }, ref) => {
    const startDateString = new Date(startdate).toISOString().split("T")[0];
    const endDateString = new Date(enddate).toISOString().split("T")[0];
    
    const isMobile = useMediaQuery("(max-width:600px)");

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    const tableRef = useRef(null);

    const handlePrint = useReactToPrint({
      content: () => tableRef.current,
      pageStyle: () => `
      @media print {
        @page {size: landscape;}
        html, body {
          height: fit-content;
      }
      }
      `,
    });

    const data = useMemo(() => dataset, [dataset]);

    const columns = useMemo(
      () => [
        {
          Header: "Date",
          accessor: "date",
        },
        {
          Header: "Parameter",
          accessor: "parameter.name",
        },
        {
          Header: "Unit",
          accessor: "unit",
        },
        {
          Header: "Tag",
          accessor: "parameter.tag.name",
        },
        {
          Header: "Result",
          accessor: "result",
        },
      ],
      []
    );

    const { headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    });

    // create download data format
    const downloadData = useMemo(() => {
      const formattedData = [];
      const headerRow = [];
      headerGroups[0].headers.forEach((headerObj) =>
        headerRow.push(headerObj["Header"])
      );
      formattedData.push(headerRow);
      rows.forEach((rowObj) => {
        prepareRow(rowObj);
        formattedData.push(rowObj.cells.map((obj) => obj.value));
      });
      return formattedData;
    }, [headerGroups, prepareRow, rows]);

    return (
      <Box
        p={3}
        minHeight={"100%"}
        minWidth={"100vw"}
        maxWidth={"100vw"}
        ref={ref}
        position="absolute"
        top={0}
        style={{ backgroundColor: "white" }}
      >
        <Box display="flex" alignItems="center">
          <IconButton onClick={onClose}>
            <CloseIcon style={{ height: "50px", width: "50px" }} />
          </IconButton>

          <Box>
            <div>{name + " "}Data</div>
            <div>
              {startDateString} to {endDateString}
            </div>
          </Box>
          <div style={{ flex: "1" }}></div>

          {isMobile ? (
            <IconButton
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              ref={mobileMenuRef}
            >
              <MoreVertIcon />
              <Menu
                open={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                anchorEl={mobileMenuRef.current}
              >
                <MenuItem
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CSVLink data={downloadData}>
                    <IconButton
                      color="inherit"
                      aria-label="download"
                      onClick={() => {}}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </CSVLink>
                  <IconButton
                    color="inherit"
                    aria-label="print"
                    onClick={handlePrint}
                  >
                    <PrintIcon />
                  </IconButton>
                </MenuItem>
              </Menu>
            </IconButton>
          ) : (
            <>
              <CSVLink data={downloadData}>
                <IconButton
                  color="inherit"
                  aria-label="download"
                  onClick={() => {}}
                >
                  <GetAppIcon style={{ height: "40px", width: "40px" }} />
                </IconButton>
              </CSVLink>
              <IconButton
                color="inherit"
                aria-label="print"
                onClick={handlePrint}
              >
                <PrintIcon style={{ height: "40px", width: "40px" }} />
              </IconButton>
            </>
          )}
        </Box>
        <div
          style={{
            overflowX: "auto",
            margin: "32px auto",
            width: isMobile ? "95%" : "90%",
          }}
        >
          <DatasetTable data={data} columns={columns} ref={tableRef} />
        </div>
      </Box>
    );
  }
);

export default DatasetContainer;
