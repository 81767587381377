import { Box, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import CustomTextField from "../CustomTextField";

const filetypes = [".xls, .xlsx", ".csv"];

const ImportSettingForm = ({ data, token, dispatch, isMobile }) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => setFormData(data), [data]);

  const inputStyle = { width: isMobile ? "100%" : "auto" };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      settings: {
        ...formData.settings,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleSubmit = () => {
    //need change, custom dispatch function to update upload setting
    return;
  };
  return (
    <Box p={3}>
      <Grid container spacing={3} style={{ marginTop: "8px" }}>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <InputLabel
              id="select-label"
              style={{ lineHeight: "24px" }}
            >
              File Type
            </InputLabel>
            <Select
              labelId="select-Label"
              name="filetype"
              value={formData.settings["filetype"]}
              onChange={handleChange}
              style={{ width: isMobile ? "100%" : "237px", lineHeight: "30px" }}
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              {filetypes.map((item) => (
                <MenuItem value={item} key={item}>
                  <em>{item}</em>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["reportdatecell"]}
              onChange={handleChange}
              name="reportdatecell"
              color="primary"
              label="Report Date Cell"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["samplingdatecell"]}
              onChange={handleChange}
              name="samplingdatecell"
              color="primary"
              label="Sampling Date Cell"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["plantresultdate"]}
              onChange={handleChange}
              name="plantresultdate"
              color="primary"
              label="Plant Result Date"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
      </Grid>
      {/* bottom half */}
      <Grid container spacing={3} style={{ marginTop: "80px" }}>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["parameterrow1"]}
              onChange={handleChange}
              name="parameterrow1"
              color="primary"
              label="Parameter Row(s) (Sheet 1)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["samplelable1"]}
              onChange={handleChange}
              name="samplelable1"
              color="primary"
              label="Sample Label Start (Sheet 1)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["analysisdate1"]}
              onChange={handleChange}
              name="analysisdate1"
              color="primary"
              label="Analysis Date Row (Sheet 1)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["parameterrow2"]}
              onChange={handleChange}
              name="parameterrow2"
              color="primary"
              label="Parameter Row(s) (Sheet 2)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["samplelable2"]}
              onChange={handleChange}
              name="samplelable2"
              color="primary"
              label="Sample Label Start (Sheet 2)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box width={isMobile ? "100%" : "auto"}>
            <CustomTextField
              labelSize={16}
              value={formData.settings["analysisdate2"]}
              onChange={handleChange}
              name="analysisdate2"
              color="primary"
              label="Analysis Date Row (Sheet 2)"
              inputStyle={inputStyle}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImportSettingForm;
