import { apiUrl } from "../../util";
import DatasetActionTypes from "./dataset.types";

export const fetchDatasetStart = () => ({
  type: DatasetActionTypes.FETCH_DATASET_START,
});

export const fetchDatasetFailure = (error) => ({
  type: DatasetActionTypes.FETCH_DATASET_FAILURE,
  payload: error,
});

export const fetchDatasetSuccess = (dataset) => ({
  type: DatasetActionTypes.FETCH_DATASET_SUCCESS,
  payload: dataset,
});

//tags: array[], params: array[]
export const fetchDatasetStartAsync = (
  token,
  datasetId,
  tags,
  params,
  startDate,
  endDate
) => {
  return async (dispatch) => {
    dispatch(fetchDatasetStart());
    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "GET",
      headers: currentHeaders,
      redirect: "follow",
    };

    let url = `${apiUrl}/dataset/values/${datasetId}?`;
    if (tags.length > 0) url += `tags=${JSON.stringify(tags)}&`;
    if (startDate) url += `start=${startDate}&`;
    if (endDate) url += `end=${endDate}&`;
    if (params.length > 0) url += `parameters=${JSON.stringify(params)}&`;

    try {
      const response = await fetch(url, requestOptions);
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(fetchDatasetSuccess(data));
        });
      } else {
        dispatch(fetchDatasetFailure("Could not fetch the dataset"));
      }
    } catch (error) {
      dispatch(fetchDatasetFailure("Could not fetch the dataset"));
    }
  };
};

export const resetError = () => ({
  type: DatasetActionTypes.RESET_ERROR,
});
