import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";

import UserDataActionTypes from "./userData.types";
import { updateUserData } from "./userData.utils";

const testdata = [
  { id: "1", name: "user", email: "asd@asd.com", accesslevelId: "1", active: true},
  { id: "2", name: "user2", email: "zxc@zxc.com", accesslevelId: "1", active: true},
];

const USER_DATA_INITIAL_STATE = {
  data: testdata,
  // data: null,
  isFetching: false, // initial data fetch
  isSaving: false, //updating, creating
  error: null,
  isUserDialogOpen: false,
  isAccessDialogOpen: false,
};

const userDataReducer = (state = USER_DATA_INITIAL_STATE, action) => {
  switch (action.type) {
    case UserDataActionTypes.FETCH_DATA_START:
      return {
        ...state,
        isFetching: true,
      };
    case UserDataActionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null,
      };
    case UserDataActionTypes.FETCH_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UserDataActionTypes.CREATE_USER_START:
    case UserDataActionTypes.UPDATE_USER_START:
      return {
        ...state,
        isSaving: true,
      };
    case UserDataActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        // need change
        data: [...state.data, action.payload],
      };
    case UserDataActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        // need change
        data: updateUserData(state.data, action.payload),
      };
    case UserDataActionTypes.CREATE_USER_FAILURE:
    case UserDataActionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload,
      };
    case UserDataActionTypes.TOGGLE_USER_DIALOG:
      return {
        ...state,
        isUserDialogOpen: !state.isUserDialogOpen,
      };
    case UserDataActionTypes.TOGGLE_ACCESS_DIALOG:
      return {
        ...state,
        isAccessDialogOpen: !state.isAccessDialogOpen,
      };
    case UserDataActionTypes.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const userDataContext = createContext(USER_DATA_INITIAL_STATE);

const { Provider } = userDataContext;

const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    userDataReducer,
    USER_DATA_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { UserDataProvider, userDataContext, userDataReducer };
