import { Checkbox, Grid } from "@material-ui/core";
import { updateAccessStartAsync } from "../../context/access/access.actions";

const DatasetCheckboxGroup = ({ data, dispatch, token }) => (
  <Grid container spacing={3} style={{marginTop: '8px'}}>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["waterquality"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, waterquality: !data["waterquality"] },
              token
            )
          )
        }
        name="waterquality"
        color="primary"
      />
      <label htmlFor={"waterquality"} style={{ fontSize: 18 }}>
        Water Quality
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["sourcewater"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, sourcewater: !data["sourcewater"] },
              token
            )
          )
        }
        name="sourcewater"
        color="primary"
      />
      <label htmlFor={"sourcewater"} style={{ fontSize: 18 }}>
        Source Water
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["plantprocess"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, plantprocess: !data["plantprocess"] },
              token
            )
          )
        }
        name="plantprocess"
        color="primary"
      />
      <label htmlFor={"plantprocess"} style={{ fontSize: 18 }}>
        Plant Process
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["clearwell"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, clearwell: !data["clearwell"] },
              token
            )
          )
        }
        name="clearwell"
        color="primary"
      />
      <label htmlFor={"clearwell"} style={{ fontSize: 18 }}>
        Clearwell
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["watershed"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, watershed: !data["watershed"] },
              token
            )
          )
        }
        name="watershed"
        color="primary"
      />
      <label htmlFor={"watershed"} style={{ fontSize: 18 }}>
        Watershed
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["distribution"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, distribution: !data["distribution"] },
              token
            )
          )
        }
        name="distribution"
        color="primary"
      />
      <label htmlFor={"distribution"} style={{ fontSize: 18 }}>
        Distribution Data
      </label>
    </Grid>
  </Grid>
);

export default DatasetCheckboxGroup;
