const AccessActionTypes = {
  FETCH_ACCESS_START: "FETCH_ACCESS_START",
  FETCH_ACCESS_SUCCESS: "FETCH_ACCESS_SUCCESS",
  FETCH_ACCESS_FAILURE: "FETCH_ACCESS_FAILURE",
  CREATE_ACCESS_START: "CREATE_ACCESS_START",
  CREATE_ACCESS_SUCCESS: "CREATE_ACCESS_SUCCESS",
  CREATE_ACCESS_FAILURE: "CREATE_ACCESS_FAILURE",
  UPDATE_ACCESS_START: "UPDATE_ACCESS_START",
  UPDATE_ACCESS_SUCCESS: "UPDATE_ACCESS_SUCCESS",
  UPDATE_ACCESS_FAILURE: "UPDATE_ACCESS_FAILURE",
  RESET_ERROR: "RESET_ERROR",
};

export default AccessActionTypes;
