import { useContext } from "react";
import Home from "./components/Home/Home";
import AuthPage from "./components/AuthPage/AuthPage";
import { authContext } from "./context/user/user.reducer";

function App() {
  const { state } = useContext(authContext);
  return <>{state.currentUser ? <Home /> : <AuthPage />}</>;
}

export default App;
