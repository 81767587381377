import { apiUrl } from "../../util";
import UserDataActionTypes from "./userData.types";

export const fetchDataStart = () => ({
  type: UserDataActionTypes.FETCH_DATA_START,
});

export const fetchDataFailure = (error) => ({
  type: UserDataActionTypes.FETCH_DATA_FAILURE,
  payload: error,
});

export const fetchDataSuccess = (userData) => ({
  type: UserDataActionTypes.FETCH_DATA_SUCCESS,
  payload: userData,
});

export const fetchDataStartAsync = (token) => {
  return async (dispatch) => {
    dispatch(fetchDataStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);

    let requestOptions = {
      method: "GET",
      headers: currentHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/users/getusers`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(fetchDataSuccess(data));
        });
      } else {
        dispatch(fetchDataFailure("Could not fetch user data"));
      }
    } catch (error) {
      dispatch(fetchDataFailure("Could not fetch user data"));
    }
  };
};

export const createUserStart = () => ({
  type: UserDataActionTypes.CREATE_USER_START,
});

export const createUserFailure = (error) => ({
  type: UserDataActionTypes.CREATE_USER_FAILURE,
  payload: error,
});

export const createUserSuccess = (newUser) => ({
  type: UserDataActionTypes.CREATE_USER_SUCCESS,
  payload: newUser,
});

export const createUserStartAsync = (name, email, accessLevel, token) => {
  return async (dispatch) => {
    dispatch(createUserStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "POST",
      headers: currentHeaders,
      body: JSON.stringify({ name, email, accessLevel }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/users/createuser`,
        requestOptions
      );
      if (response.status === 200) {
        // returns new user
        response.json().then((data) => {
          // data: obj{id: string, name: string, email: string, accesslevelId: string}
          dispatch(createUserSuccess(data));
        });
      } else {
        dispatch(createUserFailure("Could not create new user"));
      }
    } catch (error) {
      dispatch(createUserFailure("Could not create new user"));
    }
  };
};

export const updateUserStart = () => ({
  type: UserDataActionTypes.UPDATE_USER_START,
});

export const updateUserFailure = (error) => ({
  type: UserDataActionTypes.UPDATE_USER_FAILURE,
  payload: error,
});

export const updateUserSuccess = (updatedUser) => ({
  type: UserDataActionTypes.UPDATE_USER_SUCCESS,
  payload: updatedUser,
});

export const updateUserStartAsync = (
  id,
  name,
  email,
  accessLevel,
  token,
  active = true,
) => {
  return async (dispatch) => {
    dispatch(updateUserStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "PUT",
      headers: currentHeaders,
      body: JSON.stringify({ id, name, email, active, accessLevel }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/users/updateuser/:${id}`,
        requestOptions
      );
      if (response.status === 200) {
        // data: obj{id: string, name: string, email: string, accesslevelId: string}
        response.json().then((data) => {
          dispatch(updateUserSuccess(data));
        });
      } else {
        dispatch(updateUserFailure("Could not update new user"));
      }
    } catch (error) {
      dispatch(updateUserFailure("Could not update new user"));
    }
  };
};

export const toggleUserDialog = () => ({
  type: UserDataActionTypes.TOGGLE_USER_DIALOG,
});

export const toggleAccessDialog = () => ({
  type: UserDataActionTypes.TOGGLE_ACCESS_DIALOG,
});

export const resetError = () => ({
  type: UserDataActionTypes.RESET_ERROR,
});
