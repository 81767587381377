export const updateAccessData = (data, updatedAccess) => {
  let updatedData = [...data];
  let targetAccessIdx = updatedData.findIndex(
    (access) => access.id === updatedAccess.id
  );
  updatedData[targetAccessIdx] = updatedAccess;

  return updatedData;
};

export const getLevelFromId = (accesslevelId, accessData) => {
  if (accesslevelId === null) {
    return "No Access";
  }
  const targetAccessLevel = accessData.find((obj) => obj.id === accesslevelId);
  return targetAccessLevel.name;
};

export const getAccessObjFromName = (accesslevelName, accessData) => {
  if (accesslevelName === null || accessData === null) {
    return null;
  }
  const targetAccessLevel = accessData.find(
    (obj) => obj.name === accesslevelName
  );
  return targetAccessLevel;
};

export const DATA_RETRIEVAL_PROPS = [
  "waterquality",
  "sourcewater",
  "plantprocess",
  "clearwell",
  "watershed",
  "distribution",
];

//return index of the first data retrival prop that has access: true
export const checkDataRetrievalAccess = (accessLevelObj, propsArray) => {
  if (accessLevelObj === null || propsArray === null){
    return false
  }
  const existingIndex = propsArray.findIndex(
    (prop) => accessLevelObj[prop] === true
  );

  if (existingIndex > -1) {
    return existingIndex;
  }
  return false;
};
