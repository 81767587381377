import { Checkbox, Grid } from "@material-ui/core";
import { updateAccessStartAsync } from "../../context/access/access.actions";

const ModuleCheckboxGroup = ({ data, token, dispatch }) => (
  <Grid container spacing={3} style={{marginTop: '8px'}}>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["uploaddata"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, uploaddata: !data["uploaddata"] },
              token
            )
          )
        }
        name="uploaddata"
        color="primary"
      />
      <label htmlFor={"uploaddata"} style={{ fontSize: 18 }}>
        Upload Data
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["viewdashboards"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, viewdashboards: !data["viewdashboards"] },
              token
            )
          )
        }
        name="viewdashboards"
        color="primary"
      />
      <label htmlFor={"viewdashboards"} style={{ fontSize: 18 }}>
        View Dashboards
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["editusers"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, editusers: !data["editusers"] },
              token
            )
          )
        }
        name="editusers"
        color="primary"
      />
      <label htmlFor={"editusers"} style={{ fontSize: 18 }}>
        Edit/Create Users
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["editaccess"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, editaccess: !data["editaccess"] },
              token
            )
          )
        }
        name="editaccess"
        color="primary"
      />
      <label htmlFor={"editaccess"} style={{ fontSize: 18 }}>
        Edit/Create Access
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["editparameters"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, editparameters: !data["editparameters"] },
              token
            )
          )
        }
        name="editparameters"
        color="primary"
      />
      <label htmlFor={"editparameters"} style={{ fontSize: 18 }}>
        Edit Parameters
      </label>
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <Checkbox
        checked={data["editimports"]}
        onChange={() =>
          dispatch(
            updateAccessStartAsync(
              { ...data, editimports: !data["editimports"] },
              token
            )
          )
        }
        name="editimports"
        color="primary"
      />
      <label htmlFor={"editimports"} style={{ fontSize: 18 }}>
        Edit Import Settings
      </label>
    </Grid>
  </Grid>
);

export default ModuleCheckboxGroup;
