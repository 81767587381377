import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";

import ParamActionTypes from "./parameter.types";
import { updateParamData } from "./parameter.utils";

const testparam = [
  {
    id: "1",
    name: "Solid",
    units: "ml",
    dl: 0.5,
    aliases: ["aliase1", "aliase2"],
    tagId: "1",
  },
  {
    id: "2",
    name: "TestParam",
    units: "ml",
    dl: 0.5,
    aliases: ["aliase1", "aliase2"],
    tagId: "1",
  },
];

const testtag = [
  {
    id: "1", //not documented in test
    name: "THM",
    parameters: [{ id: "1", name: "solid" }],
  },
];

const PARAM_INITIAL_STATE = {
  allParams: testparam, // array[{name: string}]
  // allParams: null,
  allTags: testtag, // array[{name: string}]
  // allTags: null,
  isFetching: false,
  error: null,
  isDialogOpen: false,
};

const paramReducer = (state = PARAM_INITIAL_STATE, action) => {
  switch (action.type) {
    case ParamActionTypes.FETCH_PARAMETERS_START:
    case ParamActionTypes.FETCH_TAGS_START:
    case ParamActionTypes.CREATE_PARAMETER_START:
    case ParamActionTypes.UPDATE_PARAMETER_START:
      return {
        ...state,
        isFetching: true,
      };
    case ParamActionTypes.FETCH_PARAMETERS_SUCCESS:
      return {
        ...state,
        allParams: action.payload,
        isFetching: false,
        error: null,
      };
    case ParamActionTypes.FETCH_TAGS_SUCCESS:
      return {
        ...state,
        allTags: action.payload,
        isFetching: false,
        error: null,
      };
    case ParamActionTypes.FETCH_PARAMETERS_FAILURE:
    case ParamActionTypes.FETCH_TAGS_FAILURE:
    case ParamActionTypes.CREATE_PARAMETER_FAILURE:
    case ParamActionTypes.UPDATE_PARAMETER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ParamActionTypes.CREATE_PARAMETER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allParams: [...state.allParams, action.payload],
      };
    case ParamActionTypes.UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        allParams: updateParamData(state.allParams, action.payload),
      };
    case ParamActionTypes.TOGGLE_PARAMETER_DIALOG:
      return {
        ...state,
        isDialogOpen: !state.isDialogOpen,
      };
    case ParamActionTypes.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const paramContext = createContext(PARAM_INITIAL_STATE);

const { Provider } = paramContext;

const ParamProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    paramReducer,
    PARAM_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { ParamProvider, paramContext, paramReducer };
