const CustomTextField = ({
  label,
  type,
  onChange,
  value,
  name,
  required,
  disabled,
  labelSize = 18,
  inputStyle,
}) => {
  return (
    <>
      <label htmlFor={name} style={{ fontSize: labelSize }}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        required={required}
        disabled={disabled}
        style={{
          borderRadius: 4,
          display: "block",
          fontSize: 18,
          lineHeight: "28px",
          border: "1px solid gray",
          padding: 8,
          backgroundColor: "#f0f0f0",
          ...inputStyle,
        }}
      />
    </>
  );
};

export default CustomTextField;
