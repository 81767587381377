import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";

import UploadActionTypes from "./upload.types";

const UPLOAD_INITIAL_STATE = {
  isUploading: false,
  uploadComplete: false,
  error: null, //file parse error
  uploadFileError: null,
};

const uploadReducer = (state = UPLOAD_INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_START:
      return {
        ...state,
        isUploading: true,
      };
    case UploadActionTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
        uploadComplete: true,
        error: null,
      };
    case UploadActionTypes.UPLOAD_FAILURE:
      return {
        ...state,
        isUploading: false,
        error: action.payload,
        uploadComplete: true,
      };
    case UploadActionTypes.RESTART_UPLOAD:
      return {
        ...state,
        isUploading: false,
        uploadComplete: false,
        error: null,
        uploadFileError: null,
      };
    case UploadActionTypes.FILE_UPLOAD_ERROR:
      return {
        ...state,
        uploadFileError: action.payload,
      };
    case UploadActionTypes.RESET_UPLOAD_ERROR:
      return {
        ...state,
        uploadFileError: null,
      };
    default:
      return state;
  }
};

const uploadContext = createContext(UPLOAD_INITIAL_STATE);

const { Provider } = uploadContext;

const UploadProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    uploadReducer,
    UPLOAD_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { UploadProvider, uploadContext, uploadReducer };
