import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";

import DatasetActionTypes from "./dataset.types";

const testdata = [
  {
    samplingpoint: 'test',
    datasheet: 'test',
    date:'2000',
    week: '01',
    secondarydate: '2000',
    tertiarydate: '2000',
    result: 'test result',
    origresult: 'test',
    unit: 'testunit',
    paramunit: 'test',
    flag: 'test',
    flow: 'test',
    parameterId: 'test',
    datasetId: 'test',
    parameter: {id: 'test', name: 'parameter name', tag: {id: 'test', name: "test tagname"}}
  },
  {
    samplingpoint: 'test2',
    datasheet: 'test2',
    date:'2001',
    week: '01',
    secondarydate: '2000',
    tertiarydate: '2000',
    result: 'test result2',
    origresult: 'test',
    unit: 'testunit2',
    paramunit: 'test',
    flag: 'test',
    flow: 'test',
    parameterId: 'test',
    datasetId: 'test',
    parameter: {id: 'test', name: 'parameter name2', tag: {id: 'test', name: "test tagname2"}}
  },
];

const DATASET_INITIAL_STATE = {
  data: testdata, 
  // data: null,
  isFetching: false, // initial data fetch
  error: null,
};

const datasetReducer = (state = DATASET_INITIAL_STATE, action) => {
  switch (action.type) {
    case DatasetActionTypes.FETCH_DATASET_START:
      return {
        ...state,
        isFetching: true,
      };
    case DatasetActionTypes.FETCH_DATASET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        error: null,
      };
    case DatasetActionTypes.FETCH_DATASET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case DatasetActionTypes.RESET_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
};

const datasetContext = createContext(DATASET_INITIAL_STATE);

const { Provider } = datasetContext;

const DatasetProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    datasetReducer,
    DATASET_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { DatasetProvider, datasetContext, datasetReducer };
