const CustomButton = ({ type, children, invert, onClick, style, disabled=false }) => {
  return (
    <>
      <button
        type={type}
        style={{
          backgroundColor: invert ? "white" : "#474747",
          color: invert ? "#474747" : "white",
          border: invert ? "none" : "inherit",
          ...style
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {children.toUpperCase()}
      </button>
    </>
  );
};

export default CustomButton;
