import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { useContext, useEffect, useRef, useState } from "react";
import ModuleContainer from "../ModuleContainer/ModuleContainer";
import SpecialModuleActions from "../SpecialModuleActions";
import { authContext } from "../../context/user/user.reducer";
import { signOut } from "../../context/user/user.actions";
import { accessContext } from "../../context/access/access.reducer";
import { fetchAccessStartAsync } from "../../context/access/access.actions";
import {
  checkDataRetrievalAccess,
  DATA_RETRIEVAL_PROPS,
  getAccessObjFromName,
} from "../../context/access/access.utils";

const Home = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuElement = useRef(null);

  const [currentModule, setCurrentModule] = useState(null);

  const { state, dispatch } = useContext(authContext);
  const access = useContext(accessContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (!access.state.data) {
      access.dispatch(fetchAccessStartAsync(state.currentUser.token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleMenu = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const loadModule = (moduleName) => {
    setCurrentModule(moduleName);
  };

  const currentAccessLevel = getAccessObjFromName(
    state.currentUser.accesslevel.name,
    access.state.data
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {currentModule ?? "Home"}
          </Typography>
          {isMobile ? null : (
            <SpecialModuleActions
              currentModule={currentModule}
              isMobile={false}
            />
          )}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
            ref={menuElement}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={menuElement.current}
            keepMounted
            open={isMenuOpen}
            onClose={toggleMenu}
          >
            <MenuItem
              onClick={toggleMenu}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isMobile && (
                <SpecialModuleActions
                  currentModule={currentModule}
                  isMobile={true}
                />
              )}
              <IconButton
                color="inherit"
                aria-label="add"
                onClick={() => dispatch(signOut())}
              >
                <MeetingRoomIcon />
              </IconButton>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer open={isDrawerOpen} onClose={toggleDrawer} anchor={"left"}>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          style={{
            width: "325px",
            padding: "32px 0",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6">BPWTP</Typography>
            <Typography variant="h6">Laboratory Database</Typography>
            <Typography variant="h6">v1.0.0</Typography>
          </Box>
          <Divider />
          <List>
            {[
              {
                text: "Dashboards",
                access: currentAccessLevel
                  ? currentAccessLevel["viewdashboards"]
                  : false,
              },
              {
                text: "Data Retrieval",
                access:
                  typeof checkDataRetrievalAccess(
                    currentAccessLevel,
                    DATA_RETRIEVAL_PROPS
                  ) === "number",
              },
              {
                text: "Upload Data",
                access: currentAccessLevel
                  ? currentAccessLevel["uploaddata"]
                  : false,
              },
            ].map((obj) => (
              <ListItem
                button
                key={obj.text}
                onClick={() => loadModule(obj.text)}
                disabled={!obj.access}
              >
                <ListItemText primary={obj.text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Typography
            style={{
              padding: "8px 16px",
              cursor: "default",
            }}
          >
            Configuration
          </Typography>
          <div style={{ paddingLeft: "16px" }}>
            <List>
              {[
                {
                  text: "User Management",
                  access: currentAccessLevel
                    ? currentAccessLevel["editusers"]
                    : false,
                },
                {
                  text: "Import Settings",
                  access: currentAccessLevel
                    ? currentAccessLevel["editimports"]
                    : false,
                },
                {
                  text: "Parameters",
                  access: currentAccessLevel
                    ? currentAccessLevel["editparameters"]
                    : false,
                },
              ].map((obj) => (
                <ListItem
                  button
                  key={obj.text}
                  onClick={() => loadModule(obj.text)}
                  disabled={!obj.access}
                >
                  <ListItemText primary={obj.text} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
      <ModuleContainer currentModule={currentModule} />
    </>
  );
};

export default Home;
