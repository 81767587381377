import { Box, Dialog, Paper, Snackbar, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  fetchParamStartAsync,
  fetchTagStartAsync,
  resetError,
  toggleParameterDialog,
} from "../../context/parameter/parameter.actions";
import { paramContext } from "../../context/parameter/parameter.reducer";
import { getTagNameFromId } from "../../context/parameter/parameter.utils";
import { authContext } from "../../context/user/user.reducer";
import CustomTable from "../CustomTable";
import ParamDialogForm from "./ParamDialogForm";

const Parameters = (props) => {
  const { state, dispatch } = useContext(paramContext);

  const user = useContext(authContext);
  const token = user.state.currentUser.token;

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (state.allParams === null) {
      dispatch(fetchParamStartAsync(token));
    }
    if (state.allTags === null) {
      dispatch(fetchTagStartAsync(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentEdit, setCurrentEdit] = useState(null);

  const onEdit = (data = null) => {
    if (data) {
      setCurrentEdit(data);
    }
    dispatch(toggleParameterDialog());
  };

  const onParamDialogClose = () => {
    dispatch(toggleParameterDialog());
    // timeout to prevent header change onclose
    setTimeout(() => setCurrentEdit(null), 300);
  };

  const data = useMemo(
    () =>
      state.allParams
        ? state.allParams.map((paramObj) => ({
            ...paramObj,
            tagName: getTagNameFromId(paramObj.tagId, state.allTags),
          }))
        : [],
    [state.allParams, state.allTags]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Tag",
        accessor: "tagName",
      },
      {
        Header: "Alias(es)",
        id: "aliases",
        accessor: (data) => data.aliases.join(", "),
      },
      {
        Header: "Default Units",
        accessor: "units",
      },
      {
        Header: "Default DL",
        accessor: "dl",
      },
    ],
    []
  );
  return (
    <div {...props}>
      <Paper
        style={{
          overflowX: "auto",
          margin: "32px auto",
          width: isMobile ? "95%" : "90%",
        }}
      >
        <CustomTable columns={columns} data={data} onEdit={onEdit} />
      </Paper>
      <Dialog
        onClose={onParamDialogClose}
        aria-labelledby="param-dialog"
        open={state.isDialogOpen}
      >
        <ParamDialogForm
          currentEdit={currentEdit}
          onClose={onParamDialogClose}
          param={{ state, dispatch }}
          isMobile={isMobile}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: isMobile ? "center" : "right",
          }}
          open={!!state.error}
          autoHideDuration={6000}
          onClose={() => dispatch(resetError())}
        >
          <Box
            style={{
              backgroundColor: "lightgoldenrodyellow",
              padding: 20,
              minWidth: "300px",
            }}
          >
            {state.error}
          </Box>
        </Snackbar>
      </Dialog>
    </div>
  );
};

export default Parameters;
