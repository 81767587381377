import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    background: {
      default: grey[300],
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: "gray",
      },
    },
    MuiChip: {
      root: {
        margin: "8px 4px",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 18,
      },
    },
    MuiCssBaseline: {
      "@global": {
        td: {
          fontSize: 20,
          border: "1px solid black",
          padding: 12,
        },
        th: {
          fontSize: 20,
          border: "1px solid black",
          padding: 12,
          backgroundColor: "lightcyan",
        },
        table: {
          minWidth: "900px",
          width: '100%',
          border: "1px solid black",
          borderCollapse: "collapse",
        },
        button: {
          borderRadius: "4px",
          padding: "8px 22px",
          fontSize: 18,
          cursor: "pointer",
        },
        a: {
          color: "inherit",
        },
      },
    },
  },
});

function ThemeWrapper({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeWrapper;
