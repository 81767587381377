import {
  Box,
  Checkbox,
  Chip,
  Container,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Slide,
  Tab,
  Typography,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext, useEffect, useState } from "react";
import { accessContext } from "../../context/access/access.reducer";
import { authContext } from "../../context/user/user.reducer";
import TabPanel from "./TabPanel";
import { DatePicker } from "@material-ui/pickers";
import {
  checkDataRetrievalAccess,
  DATA_RETRIEVAL_PROPS,
  getAccessObjFromName,
} from "../../context/access/access.utils";
import { paramContext } from "../../context/parameter/parameter.reducer";
import {
  fetchParamStartAsync,
  fetchTagStartAsync,
} from "../../context/parameter/parameter.actions";
import { findParamFromTagIds } from "../../context/parameter/parameter.utils";
import CustomButton from "../CustomButton";
import { datasetContext } from "../../context/dataset/dataset.reducer";
import { fetchDatasetStartAsync } from "../../context/dataset/dataset.actions";
import DatasetContainer from "./DatasetContainer";

const tabsArray = [
  { label: "Water Quality", name: "waterquality" },
  { label: "Source Water", name: "sourcewater" },
  { label: "Plant Process", name: "plantprocess" },
  { label: "Clearwell", name: "clearwell" },
  { label: "Watershed", name: "watershed" },
  { label: "Distribution", name: "distribution" },
];

const DataRetrieval = (props) => {
  const user = useContext(authContext);
  const access = useContext(accessContext);
  const param = useContext(paramContext);
  const dataset = useContext(datasetContext);

  const accessLevel = getAccessObjFromName(
    user.state.currentUser.accesslevel.name,
    access.state.data
  );

  const [tabValue, setTabValue] = useState(
    //initial state
    checkDataRetrievalAccess(accessLevel, DATA_RETRIEVAL_PROPS)
  );
  const [dataFilter, setDataFilter] = useState({
    startdate: "2019",
    enddate: "2020",
    tags: [], // array of tag ids
    params: [], // array of param ids
  });

  const [isParamDialogOpen, setIsParamDialogOpen] = useState(false);
  const [isDatasetDialogOpen, setIsDatasetDialogOpen] = useState(false);

  const token = user.state.currentUser.token;
  useEffect(() => {
    if (param.state.allParams === null) {
      param.dispatch(fetchParamStartAsync(token));
    }
    if (param.state.allTags === null) {
      param.dispatch(fetchTagStartAsync(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  useEffect(() => {
    const allParamsIdFromTags = findParamFromTagIds(
      dataFilter.tags,
      param.state.allParams
    );
    if (dataFilter.params.length === allParamsIdFromTags.length) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
  }, [
    dataFilter.params,
    dataFilter.tags,
    param.state.allParams,
    param.state.allParams.length,
  ]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStartDateChange = (value) => {
    setDataFilter({ ...dataFilter, startdate: value });
  };

  const handleEndDateChange = (value) => {
    setDataFilter({ ...dataFilter, enddate: value });
  };

  const handleSelectCheckBox = (event, name) => {
    const existingIndex = dataFilter[name].indexOf(event.target.name);
    if (existingIndex > -1) {
      let newArray = [...dataFilter[name]];
      newArray.splice(existingIndex, 1);
      setDataFilter({ ...dataFilter, [name]: newArray });

      if (name === "tags") {
        // filter out params that belong to this tag as well
        let paramsToRemove = param.state.allParams
          .filter((paramObj) => paramObj.tagId === event.target.name)
          .map((obj) => obj.id);
        let newParams = dataFilter.tags.filter(
          (paramId) => !paramsToRemove.includes(paramId)
        );
        setDataFilter({ ...dataFilter, params: newParams, tags: newArray });
      }
    } else {
      setDataFilter({
        ...dataFilter,
        [name]: [...dataFilter[name], event.target.name],
      });
    }
  };

  const selectAllToggle = () => {
    const allParamsFromTags = findParamFromTagIds(
      dataFilter.tags,
      param.state.allParams
    );
    if (dataFilter.params.length < allParamsFromTags.length) {
      const newParamArray = allParamsFromTags.map((paramObj) => paramObj.id);
      setDataFilter({ ...dataFilter, params: newParamArray });
    } else {
      setDataFilter({ ...dataFilter, params: [] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { tags, params, startdate, enddate } = dataFilter;
    await dataset.dispatch(
      fetchDatasetStartAsync(token, tabValue, tags, params, startdate, enddate)
    );
    setIsDatasetDialogOpen(!isDatasetDialogOpen);
  };

  return (
    <div {...props}>
      <Paper position="relative">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          indicatorColor={tabValue === tabsArray.length ? "" : "primary"}
        >
          {tabsArray.map((obj, index) => (
            <Tab
              label={obj.label}
              key={obj.name}
              disabled={accessLevel === null ? true : !accessLevel[obj.name]}
              // replace index with datasetId
              value={index}
            />
          ))}
        </Tabs>
      </Paper>
      {tabsArray.map((obj, index) => (
        <TabPanel value={tabValue} index={index} key={obj.name}>
          <Container maxWidth={"sm"}>
            <form onSubmit={handleSubmit}>
              <Paper>
                <Box p={2} m={2}>
                  <Typography style={{ marginTop: "32px" }}>
                    Date Range
                  </Typography>
                  <Divider />
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    style={{
                      margin: '16px 0 32px 0'
                    }}
                  >
                    <Grid item xs={12} sm={6} >
                      <DatePicker
                        label="From:"
                        onChange={handleStartDateChange}
                        value={dataFilter.startdate}
                        views={["year"]}
                        style={{
                          width: '100%'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="To:"
                        views={["year"]}
                        onChange={handleEndDateChange}
                        value={dataFilter.enddate}
                        style={{
                          width: '100%'
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Typography style={{ marginTop: "32px" }}>Tags</Typography>
                  <Divider />
                  <Box>
                    {param.state.allTags.map((tag) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dataFilter.tags.includes(tag.id)}
                            onChange={(e) => handleSelectCheckBox(e, "tags")}
                            name={tag.id}
                            color="primary"
                          />
                        }
                        label={tag.name}
                        key={tag.name}
                      />
                    ))}
                  </Box>

                  <Typography style={{ marginTop: "32px" }} display="inline">
                    Parameters
                  </Typography>
                  <IconButton
                    onClick={() => setIsParamDialogOpen(!isParamDialogOpen)}
                  >
                    <AddCircleIcon />
                  </IconButton>
                  <Dialog
                    open={isParamDialogOpen}
                    onClose={() => setIsParamDialogOpen(!isParamDialogOpen)}
                  >
                    <Box
                      p={1}
                      minHeight={300}
                      minWidth={300}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography variant="h6">Parameters</Typography>
                      <Divider />
                      <Box flex="1" display="flex" flexDirection="column">
                        {dataFilter.tags.length > 0 ? (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSelectAllChecked}
                                  onChange={selectAllToggle}
                                  name="allCheck"
                                  color="primary"
                                />
                              }
                              label="Select All"
                              key="Select All"
                            />
                            <Divider />
                          </>
                        ) : null}
                        {findParamFromTagIds(
                          dataFilter.tags,
                          param.state.allParams
                        ).map((paramObj) => (
                          <div key={paramObj.name}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={dataFilter.params.includes(
                                    paramObj.id
                                  )}
                                  onChange={(e) =>
                                    handleSelectCheckBox(e, "params")
                                  }
                                  name={paramObj.id}
                                  color="primary"
                                />
                              }
                              label={paramObj.name}
                            />
                            <Divider />
                          </div>
                        ))}
                      </Box>
                      <Box display="flex">
                        <div style={{ flex: "1" }}></div>
                        <CustomButton
                          invert
                          type="button"
                          onClick={() =>
                            setIsParamDialogOpen(!isParamDialogOpen)
                          }
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          invert
                          type="button"
                          onClick={() =>
                            setIsParamDialogOpen(!isParamDialogOpen)
                          }
                        >
                          Confirm
                        </CustomButton>
                      </Box>
                    </Box>
                  </Dialog>
                  <Divider />
                  <Box marginTop={2} minHeight="50px">
                    {isSelectAllChecked && dataFilter.params.length > 0 ? (
                      <Chip label={"All"} />
                    ) : null}
                    {param.state.allParams
                      .filter((paramObj) =>
                        dataFilter.params.includes(paramObj.id)
                      )
                      .map((obj) => (
                        <Chip label={obj.name} key={obj.id} />
                      ))}
                  </Box>
                  <Box m={2} display="flex" justifyContent="center">
                    <CustomButton type="submit">PULL DATA</CustomButton>
                  </Box>
                </Box>
              </Paper>
            </form>
          </Container>
        </TabPanel>
      ))}
      <Slide direction="up" in={isDatasetDialogOpen} mountOnEnter unmountOnExit>
        <DatasetContainer
          dataset={dataset.state.data}
          onClose={() => setIsDatasetDialogOpen(!isDatasetDialogOpen)}
          name={tabsArray[tabValue].label}
          startdate={dataFilter.startdate}
          enddate={dataFilter.enddate}
        />
      </Slide>
    </div>
  );
};

export default DataRetrieval;
