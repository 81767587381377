import { apiUrl } from "../../util";
import UserActionTypes from "./user.types";

export const signInStart = () => ({
  type: UserActionTypes.SIGN_IN_START,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signInSuccess = (currentUser) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: currentUser,
});

export const signOut = () => ({
  type: UserActionTypes.SIGN_OUT,
});

export const signInStartAsync = (email, password) => {
  return async (dispatch) => {
    dispatch(signInStart());

    let requestOptions = {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
    };

    try {
      const response = await fetch(
        `${apiUrl}/auth/validateuser`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(signInSuccess(data));
        });
      } else {
        response.json().then((error) => {
          dispatch(signInFailure(error.text));
        });
      }
    } catch (error) {
      dispatch(signInFailure("Could not sign in"));
    }
  };
};
