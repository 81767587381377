import { Fade } from "@material-ui/core";
import React from "react";
import Dashboards from "../Dashboards/Dashboards";
import DataRetrieval from "../DataRetrieval/DataRetrieval";
import UploadData from "../UploadData/UploadData";
import UserManagement from "../UserManagement/UserManagement";
import ImportSettings from "../ImportSettings/ImportSettings";
import Parameters from "../Parameters/Parameters";
import { defaultTransition } from "../../util";

const ModuleContainer = ({ currentModule }) => {
  const moduleName = currentModule ? currentModule.replace(/\s/g, "") : null;
  // console.log(moduleName);
  return (
    <>
      <Fade
        in={moduleName === null}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <div>welcome</div>
      </Fade>
      <Fade
        in={moduleName === "Dashboards"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <Dashboards />
      </Fade>
      <Fade
        in={moduleName === "DataRetrieval"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <DataRetrieval />
      </Fade>
      <Fade
        in={moduleName === "UploadData"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <UploadData />
      </Fade>
      <Fade
        in={moduleName === "UserManagement"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <UserManagement />
      </Fade>
      <Fade
        in={moduleName === "ImportSettings"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <ImportSettings />
      </Fade>
      <Fade
        in={moduleName === "Parameters"}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <Parameters />
      </Fade>
    </>
  );
};

export default React.memo(ModuleContainer);
