export const updateUserData = (data, updatedUser) => {
  let updatedData = [...data];
  let targetUserIdx = updatedData.findIndex(
    (user) => user.id === updatedUser.id
  );

  updatedData[targetUserIdx] = updatedUser;

  return updatedData;
};

export const findAccessLevelInUserData = (accessId, userData) => {
  const targetIndex = userData.findIndex(
    (user) => user.accesslevelId === accessId
  );
  
  if (targetIndex > -1) {
    return true;
  } else {
    return false;
  }
};
