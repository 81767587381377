import {
  Box,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { defaultTransition } from "../../util";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ImportSettingForm from "./ImportSettingForm";
import { authContext } from "../../context/user/user.reducer";

const data = [
  {
    label: "Distribution",
    name: "distribution",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
  {
    label: "Water Quality",
    name: "waterquality",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
  {
    label: "Source Water",
    name: "sourcewater",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
  {
    label: "Plant Process",
    name: "plantprocess",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
  {
    label: "Clearwell",
    name: "clearwell",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
  {
    label: "Watershed",
    name: "watershed",
    settings: {
      filetype: "",
      reportdatecell: "",
      samplingdatecell: "",
      plantresultdate: "",
      parameterrow1: "",
      samplelable1: "",
      analysisdate1: "",
      parameterrow2: "",
      samplelable2: "",
      analysisdate2: "",
    },
  },
];

const ImportSettings = (props) => {
  const [currentViewIdx, setCurrentViewIdx] = useState(0);

  // mobile view state
  const [mobileNavHide, setMobileNavHide] = useState(false);
  const [mobileFormHide, setMobileFormHide] = useState(false);
  const [onNav, setOnNav] = useState(true);

  // using media query instead of device type
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (isMobile && onNav) {
      setMobileFormHide(true);
      setMobileNavHide(false);
    } else if (isMobile && !onNav) {
      setMobileFormHide(false);
      setMobileNavHide(true);
    } else {
      setMobileFormHide(false);
      setMobileNavHide(false);
    }
  }, [isMobile, onNav]);

  const user = useContext(authContext);

  return (
    <div {...props}>
      <Box p={3} minHeight={"100%"}>
        <Paper style={{ display: "flex", minHeight: "85vh" }}>
          <Fade
            in={!mobileNavHide}
            mountOnEnter
            unmountOnExit
            timeout={defaultTransition}
          >
            <Box
              minWidth="275px"
              width={isMobile ? "90%" : "auto"}
              display="flex"
              flexDirection="column"
              paddingLeft={2}
            >
              <Typography variant={"h5"} style={{ marginTop: "16px" }}>
                Dataset:
              </Typography>
              <List style={{ flex: 1 }}>
                {data.map((dataObj, index) => (
                  <ListItem
                    button
                    key={dataObj.name}
                    onClick={() => {
                      setCurrentViewIdx(index);
                      setOnNav(false);
                    }}
                    selected={index === currentViewIdx}
                  >
                    <ListItemText primary={dataObj.label} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Fade>
          <Fade
            in={currentViewIdx !== null && !mobileFormHide}
            mountOnEnter
            unmountOnExit
            timeout={defaultTransition}
          >
            <Box flex={1}>
              <Box padding="32px 8px 0px 8px">
                {isMobile && (
                  <IconButton onClick={() => setOnNav(true)}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Box display="inline-block">
                  <Typography
                    style={{ textTransform: "capitalize" }}
                  >
                    File Import Settings:
                  </Typography>
                </Box>
              </Box>
              <ImportSettingForm
                data={data[currentViewIdx]}
                token={user.state.currentUser.token}
                isMobile={isMobile}
                // need change
                dispatch={"dispatch related to import settings"}
              />
            </Box>
          </Fade>
        </Paper>
      </Box>
    </div>
  );
};

export default ImportSettings;
