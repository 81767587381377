import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Fade,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { uploadContext } from "../../context/upload/upload.reducer";
import { defaultTransition } from "../../util";
import { useDropzone } from "react-dropzone";
import { authContext } from "../../context/user/user.reducer";
import {
  resetUploadError,
  restartUpload,
  uploadStartAsync,
  fileUploadError,
} from "../../context/upload/upload.actions";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../CustomButton";

const dataList = [
  { label: "Quality Data", routeName: "qualitydata" },
  { label: "Distribution Data", routeName: "distributiondata" },
  { label: "Watershed Data", routeName: "watersheddata" },
];

const iconStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  border: "5px solid black",
  marginTop: "16px",
};

const dropzoneStyle = {
  backgroundColor: "lightgray",
  marginTop: "32px",
  minHeight: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  border: "1px gray dashed",
};

const UploadData = (props) => {
  const [routeName, setRouteName] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { state, dispatch } = useContext(uploadContext);
  const { isUploading, uploadComplete } = state;
  const user = useContext(authContext);
  const token = user.state.currentUser.token;
  const isMobile = useMediaQuery("(max-width:600px)");

  const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
    accept: ".csv, .xls, .xlsx",
    onDropAccepted: (acceptedFiles) => {
      if (routeName) {
        setUploadProgress(0);
        dispatch(uploadStartAsync(token, routeName, acceptedFiles[0]));
      }
    },
    onDropRejected: (rejectedFiles) => {
      // rejectedFiles[0].errors[0].code   //default error
      dispatch(fileUploadError("Invalid Upload File"));
    },
  });

  useEffect(() => {
    if (isUploading) {
      const timer = setInterval(() => {
        setUploadProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 5
        );
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }
  }, [isUploading]);

  const handleChange = (e) => {
    setRouteName(e.target.value);
  };

  return (
    <Container {...props} maxWidth="sm">
      <Fade
        in={!isUploading && !uploadComplete}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <Paper>
          <Box marginTop={"48px"} p={5}>
            <div>
              <InputLabel id="select-label">Dataset</InputLabel>
              <Select
                labelId="select-label"
                name="routename"
                value={routeName}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value={""}>
                  <em>None</em>
                </MenuItem>
                {dataList.map((item) => (
                  <MenuItem value={item.routeName} key={item.label}>
                    <em>{item.label}</em>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={!isMobile ? dropzoneStyle : null}
            >
              <input {...getInputProps()} />
              {!isMobile && (
                <Typography variant="body1">
                  Drop files here to start uploading
                </Typography>
              )}
              {!isMobile && (
                <Typography variant="body1" style={{ margin: "16px 0 0 0" }}>
                  or
                </Typography>
              )}
              <CustomButton
                type="button"
                onClick={() => {}}
                style={{
                  border: "none",
                  width: isMobile ? "100%" : "auto",
                  marginTop: "16px",
                }}
              >
                Select File
              </CustomButton>
            </div>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: isMobile ? "center" : "right",
            }}
            open={!!state.uploadFileError}
            autoHideDuration={6000}
            onClose={() => dispatch(resetUploadError())}
          >
            <Box
              style={{ backgroundColor: "lightgoldenrodyellow", padding: 20 }}
            >
              {state.uploadFileError}
            </Box>
          </Snackbar>
        </Paper>
      </Fade>
      <Fade in={isUploading} unmountOnExit={true} timeout={defaultTransition}>
        <Paper>
          <Box
            marginTop={"48px"}
            p={5}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="400px"
          >
            <Typography>Processing File...</Typography>
            <Box position="relative" display="inline-flex" marginTop="16px">
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={100}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  component="div"
                  color="textSecondary"
                >{`${Math.round(uploadProgress)}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Fade>
      <Fade
        in={uploadComplete}
        unmountOnExit={true}
        timeout={defaultTransition}
      >
        <Paper>
          <Box
            marginTop={"48px"}
            p={5}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="400px"
          >
            {state.error ? (
              <>
                <Typography>Unable to Upload Data</Typography>
                <CloseIcon style={{ ...iconStyle }} />
                <Typography
                  style={{
                    textDecoration: "underline",
                    marginTop: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsErrorDialogOpen(!isErrorDialogOpen)}
                >
                  View Details
                </Typography>
                <Typography
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => dispatch(restartUpload())}
                >
                  Cancel
                </Typography>
                <Dialog
                  open={isErrorDialogOpen}
                  onClose={() => setIsErrorDialogOpen(!isErrorDialogOpen)}
                >
                  <Box
                    p={3}
                    display="flex"
                    flexDirection="column"
                    minHeight="400px"
                  >
                    <Typography>File Upload Error Details</Typography>
                    <Typography>
                      Unable to process{" "}
                      {acceptedFiles.length > 0 ? acceptedFiles[0].name : null}{" "}
                      for upload
                    </Typography>
                    <Divider />
                    <Box marginTop={2} maxWidth="300px">
                      {state.error &&
                        state.error.map((error, index) => (
                          <Box
                            key={index}
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <CloseIcon style={{ alignSelf: "flex-start" }} />
                            <Typography style={{ maxWidth: "300px" }}>
                              {error}
                            </Typography>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                    <CustomButton
                      type="button"
                      invert
                      onClick={() => setIsErrorDialogOpen(!isErrorDialogOpen)}
                      style={{ alignSelf: "flex-end", marginTop: "auto" }}
                    >
                      Continue
                    </CustomButton>
                  </Box>
                </Dialog>
              </>
            ) : (
              <>
                <Typography>File Upload Success</Typography>
                <DoneIcon style={{ ...iconStyle }} />
                <Typography
                  style={{
                    textDecoration: "underline",
                    marginTop: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(restartUpload())}
                >
                  Upload Additional Data
                </Typography>
              </>
            )}
          </Box>
        </Paper>
      </Fade>
    </Container>
  );
};

export default UploadData;
