import { useContext, useEffect, useMemo, useState } from "react";
import CustomTable from "../CustomTable";
import { Box, Dialog, Paper, Snackbar, useMediaQuery } from "@material-ui/core";
import {
  resetError,
  toggleAccessDialog,
  toggleUserDialog,
} from "../../context/userData/userData.actions";
import { userDataContext } from "../../context/userData/userData.reducer";
import UserDialogForm from "./UserDialogForm";
import { accessContext } from "../../context/access/access.reducer";
import { fetchAccessStartAsync } from "../../context/access/access.actions";
import AccessDialogForm from "./AccessDialogForm";
import { authContext } from "../../context/user/user.reducer";
import { fetchDataStartAsync } from "../../context/userData/userData.actions";
import { getLevelFromId } from "../../context/access/access.utils";

const UserManagement = (props) => {
  const { state, dispatch } = useContext(userDataContext);

  const user = useContext(authContext);
  const token = user.state.currentUser.token;
  const access = useContext(accessContext);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (state.data === null) {
      dispatch(fetchDataStartAsync(token));
    }
    if (!access.state.data) {
      access.dispatch(fetchAccessStartAsync(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentEdit, setCurrentEdit] = useState(null);

  const onEdit = (data = null) => {
    if (data) {
      setCurrentEdit(data);
    }
    dispatch(toggleUserDialog());
  };

  const onUserDialogClose = () => {
    dispatch(toggleUserDialog());
    // timeout to prevent header change onclose
    setTimeout(() => setCurrentEdit(null), 300);
  };

  const data = useMemo(
    () =>
    state.data ?
      state.data.map((userObj) => ({
        ...userObj,
        accesslevel: getLevelFromId(userObj.accesslevelId, access.state.data),
      })) : []
    ,
    [access.state.data, state.data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Access Level",
        accessor: "accesslevel",
      },
    ],
    []
  );

  return (
    <div {...props}>
      <Paper
        style={{
          overflowX: "auto",
          margin: "32px auto",
          width: isMobile ? "95%" : "90%",
        }}
      >
        <CustomTable columns={columns} data={data} onEdit={onEdit} />
      </Paper>
      <Dialog
        onClose={onUserDialogClose}
        aria-labelledby="user-dialog"
        open={state.isUserDialogOpen}
      >
        <UserDialogForm currentEdit={currentEdit} onClose={onUserDialogClose} isMobile={isMobile} />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: isMobile ? "center" : "right",
          }}
          open={!!state.error}
          autoHideDuration={6000}
          onClose={() => dispatch(resetError())}
        >
          <Box
            style={{
              backgroundColor: "lightgoldenrodyellow",
              padding: 20,
              minWidth: "300px",
            }}
          >
            {state.error}
          </Box>
        </Snackbar>
      </Dialog>
      <Dialog
        onClose={() => dispatch(toggleAccessDialog())}
        aria-labelledby="access-dialog"
        open={state.isAccessDialogOpen}
        fullScreen
      >
        <AccessDialogForm
          data={access.state.data}
          userData={state.data}
          onClose={() => dispatch(toggleAccessDialog())}
          isMobile={isMobile}
        />
      </Dialog>
    </div>
  );
};

export default UserManagement;
