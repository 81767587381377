import { apiUrl } from "../../util";
import AccessActionTypes from "./access.types";

export const fetchAccessStart = () => ({
  type: AccessActionTypes.FETCH_ACCESS_START,
});

export const fetchAccessFailure = (error) => ({
  type: AccessActionTypes.FETCH_ACCESS_FAILURE,
  payload: error,
});

export const fetchAccessSuccess = (accessData) => ({
  type: AccessActionTypes.FETCH_ACCESS_SUCCESS,
  payload: accessData,
});

export const fetchAccessStartAsync = (token) => {
  return async (dispatch) => {
    dispatch(fetchAccessStart());
    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "GET",
      headers: currentHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/access/getaccess`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch(fetchAccessSuccess(data));
        });
      } else {
        dispatch(fetchAccessFailure("Could not fetch Access data"));
      }
    } catch (error) {
      dispatch(fetchAccessFailure("Could not fetch Access data"));
    }
  };
};

export const createAccessStart = () => ({
  type: AccessActionTypes.CREATE_ACCESS_START,
});

export const createAccessFailure = (error) => ({
  type: AccessActionTypes.CREATE_ACCESS_FAILURE,
  payload: error,
});

export const createAccessSuccess = (newAccess) => ({
  type: AccessActionTypes.CREATE_ACCESS_SUCCESS,
  payload: newAccess,
});

export const createAccessStartAsync = (accessObj, token) => {
  return async (dispatch) => {
    dispatch(createAccessStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "POST",
      headers: currentHeaders,
      body: JSON.stringify(accessObj),
    };

    try {
      const response = await fetch(
        `${apiUrl}/access/createaccess`,
        requestOptions
      );
      if (response.status === 200) {
        response.json().then((data) => {
          // data: obj{id: string, name: string, [accessProps]: boolean}
          dispatch(createAccessSuccess(data));
        });
      } else {
        dispatch(createAccessFailure("Could not create new access"));
      }
    } catch (error) {
      dispatch(createAccessFailure("Could not create new access"));
    }
  };
};

export const updateAccessStart = () => ({
  type: AccessActionTypes.UPDATE_ACCESS_START,
});

export const updateAccessFailure = (error) => ({
  type: AccessActionTypes.UPDATE_ACCESS_FAILURE,
  payload: error,
});

export const updateAccessSuccess = (updatedUser) => ({
  type: AccessActionTypes.UPDATE_ACCESS_SUCCESS,
  payload: updatedUser,
});

export const updateAccessStartAsync = (accessObj, token) => {
  return async (dispatch) => {
    dispatch(updateAccessStart());

    let currentHeaders = new Headers();
    currentHeaders.append("Authorization", "Bearer " + token);
    let requestOptions = {
      method: "PUT",
      headers: currentHeaders,
      body: JSON.stringify(accessObj),
    };

    try {
      const response = await fetch(
        `${apiUrl}/access/updateaccess/:${accessObj.id}`,
        requestOptions
      );
      if (response.status === 200) {
        // data: obj{id: string, name: string, [accessProps]: boolean}
        response.json().then((data) => {
          dispatch(updateAccessSuccess(data));
        });
      } else {
        dispatch(updateAccessFailure("Could not update access"));
      }
    } catch (error) {
      dispatch(updateAccessFailure("Could not update access"));
    }
  };
};

export const resetError = () => ({
  type: AccessActionTypes.RESET_ERROR,
});
