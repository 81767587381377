import {
  Box,
  Dialog,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import CustomButton from "../CustomButton";
import CustomTextField from "../CustomTextField";
import { authContext } from "../../context/user/user.reducer";
import CloseIcon from "@material-ui/icons/Close";
import ModuleCheckboxGroup from "./ModuleCheckboxGroup";
import DatasetCheckboxGroup from "./DatasetCheckboxGroup";
import { accessContext } from "../../context/access/access.reducer";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  createAccessStartAsync,
  resetError,
  updateAccessStartAsync,
} from "../../context/access/access.actions";
import { defaultTransition } from "../../util";
import { findAccessLevelInUserData } from "../../context/userData/userData.utils";

const AccessDialogForm = ({ data, onClose, userData, isMobile }) => {
  const [currentViewIdx, setCurrentViewIdx] = useState(0);
  const [isAccessNameDialogOpen, setIsAccessNameDialogOpen] = useState(false);
  const [accessName, setAccessName] = useState("");
  const [isEditAccessName, setIsEditAccessName] = useState(true);

  //mobile only view
  const [mobileNavHide, setMobileNavHide] = useState(false);
  const [mobileAccessHide, setMobileAccessHide] = useState(false);
  const [onNav, setOnNav] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuElement = useRef(null);

  useEffect(() => {
    if (isMobile && onNav) {
      setMobileAccessHide(true);
      setMobileNavHide(false);
    } else if (isMobile && !onNav) {
      setMobileAccessHide(false);
      setMobileNavHide(true);
    } else {
      setMobileAccessHide(false);
      setMobileNavHide(false);
    }
  }, [isMobile, onNav]);

  const user = useContext(authContext);
  const access = useContext(accessContext);

  const onCreateAccessName = () => {
    setIsEditAccessName(false);
    setIsAccessNameDialogOpen(!isAccessNameDialogOpen);
  };

  const onEditAccessName = (name) => {
    setAccessName(name);
    setIsEditAccessName(true);
    setIsAccessNameDialogOpen(!isAccessNameDialogOpen);
  };

  const onCloseAccessNameDialog = () => {
    setIsAccessNameDialogOpen(!isAccessNameDialogOpen);
    setAccessName("");
  };

  const onAccessNameSave = () => {
    if (isEditAccessName) {
      access.dispatch(
        updateAccessStartAsync(
          { ...data[currentViewIdx], name: accessName },
          user.state.currentUser.token
        )
      );
    } else {
      // needs change
      let newObj = { ...data[0] };
      delete newObj.id;
      access.dispatch(
        createAccessStartAsync(
          { ...newObj, name: accessName },
          user.state.currentUser.token
        )
      );
    }
  };

  const onDeleteAccess = () => {
    const inUse = findAccessLevelInUserData(data[currentViewIdx].id, userData);
    if (inUse) {
      console.log("current access level is being used");
    } else {
      // need change
      console.log("delete access");
    }
  };

  const AccessFormSnackbar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: isMobile ? "center" : "right",
      }}
      open={!!access.state.error}
      autoHideDuration={6000}
      onClose={() => access.dispatch(resetError())}
    >
      <Box
        style={{
          backgroundColor: "lightgoldenrodyellow",
          padding: 20,
          minWidth: "300px",
        }}
      >
        {access.state.error}
      </Box>
    </Snackbar>
  );

  return (
    <Box p={3} minHeight={"100%"}>
      {mobileNavHide ? null : (
        <Box p={2}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography style={{ display: "inline" }}>Access Levels</Typography>
        </Box>
      )}
      <Box display="flex" minHeight={"85vh"}>
        {/* {mobileNavHide ? null : ( */}
        <Fade
          in={!mobileNavHide}
          mountOnEnter
          unmountOnExit
          timeout={defaultTransition}
        >
          <Box
            minWidth="275px"
            width={isMobile ? "90%" : "auto"}
            display="flex"
            flexDirection="column"
            paddingLeft={2}
          >
            <List style={{ flex: 1 }}>
              {data.map((accessObj, index) => (
                <ListItem
                  button
                  key={accessObj.id}
                  onClick={() => {
                    setCurrentViewIdx(index);
                    setOnNav(false);
                  }}
                >
                  <ListItemText primary={accessObj.name} style={{ textTransform: 'capitalize'}}/>
                </ListItem>
              ))}
            </List>
            <Box
              display="flex"
              minWidth="300px"
              justifyContent={isMobile ? "flex-end" : "center"}
            >
              {isMobile ? (
                <IconButton
                  onClick={onCreateAccessName}
                  style={{ marginLeft: "auto" }}
                >
                  <AddCircleIcon style={{ height: 50, width: 50 }} />
                </IconButton>
              ) : (
                <CustomButton
                  onClick={onCreateAccessName}
                  style={{ width: "100%" }}
                >
                  New Level
                </CustomButton>
              )}
            </Box>
          </Box>
          {/* )} */}
        </Fade>
        <Dialog
          onClose={onCloseAccessNameDialog}
          aria-labelledby="access-name-dialog"
          open={isAccessNameDialogOpen}
        >
          <Box p={2}>
            <Typography variant="h5">
              {isEditAccessName ? "Edit Access Level Name" : "New Access Level"}
            </Typography>
            <CustomTextField
              label={"Level Name"}
              type="text"
              value={accessName}
              name={"name"}
              onChange={(e) => setAccessName(e.target.value)}
            />
            <CustomButton invert onClick={onCloseAccessNameDialog}>
              Cancel
            </CustomButton>
            <CustomButton invert onClick={onAccessNameSave}>
              Save
            </CustomButton>
          </Box>
          <AccessFormSnackbar />
        </Dialog>

        <AccessFormSnackbar />
        {/* {currentViewIdx !== null && !mobileAccessHide && ( */}
        <Fade
          in={currentViewIdx !== null && !mobileAccessHide}
          mountOnEnter
          unmountOnExit
          timeout={defaultTransition}
        >
          <Box flex={1}>
            <Box display="flex" alignItems="center">
              {isMobile && (
                <IconButton onClick={() => setOnNav(true)}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography
                style={{ display: "inline", textTransform: "capitalize" }}
              >
                {data[currentViewIdx].name}
              </Typography>
              {isMobile ? (
                <>
                <span style={{flex: '1'}}></span>
                  <IconButton
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    ref={mobileMenuElement}
                  >
                    <MoreHorizIcon style={{ width: "40px", height: "40px" }} />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={mobileMenuElement.current}
                    keepMounted
                    open={isMobileMenuOpen}
                    onClose={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  >
                    <MenuItem
                      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          onEditAccessName(data[currentViewIdx].name)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={onDeleteAccess}>
                        <DeleteIcon />
                      </IconButton>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={() => onEditAccessName(data[currentViewIdx].name)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={onDeleteAccess}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </Box>
            <Box marginTop="16px">
              <Typography>Module Access:</Typography>
              <Divider style={{ backgroundColor: "black" }} />
              <ModuleCheckboxGroup
                data={data[currentViewIdx]}
                token={user.state.currentUser.token}
                dispatch={access.dispatch}
              />
            </Box>
            <Box marginTop="16px">
              <Typography>Access to Datasets:</Typography>
              <Divider style={{ backgroundColor: "black" }} />
              <DatasetCheckboxGroup
                data={data[currentViewIdx]}
                token={user.state.currentUser.token}
                dispatch={access.dispatch}
              />
            </Box>
          </Box>
          {/* )} */}
        </Fade>
      </Box>
    </Box>
  );
};

export default AccessDialogForm;
