const ParamActionTypes = {
  FETCH_PARAMETERS_START: "FETCH_PARAMETERS_START",
  FETCH_PARAMETERS_SUCCESS: "FETCH_PARAMETERS_SUCCESS",
  FETCH_PARAMETERS_FAILURE: "FETCH_PARAMETERS_FAILURE",
  FETCH_TAGS_START: "FETCH_TAGS_START",
  FETCH_TAGS_SUCCESS: "FETCH_TAGS_SUCCESS",
  FETCH_TAGS_FAILURE: "FETCH_TAGS_FAILURE",
  CREATE_PARAMETER_START: "CREATE_PARAMETER_START",
  CREATE_PARAMETER_SUCCESS: "CREATE_PARAMETER_SUCCESS",
  CREATE_PARAMETER_FAILURE: "CREATE_PARAMETER_FAILURE",
  UPDATE_PARAMETER_START: "UPDATE_PARAMETER_START",
  UPDATE_PARAMETER_SUCCESS: "UPDATE_PARAMETER_SUCCESS",
  UPDATE_PARAMETER_FAILURE: "UPDATE_PARAMETER_FAILURE",
  TOGGLE_PARAMETER_DIALOG: "TOGGLE_PARAMETER_DIALOG",
  RESET_ERROR: "RESET_ERROR",
};

export default ParamActionTypes;
