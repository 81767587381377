import { useContext } from "react";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { userDataContext } from "../context/userData/userData.reducer";
import {
  toggleAccessDialog,
  toggleUserDialog,
} from "../context/userData/userData.actions";
import { paramContext } from "../context/parameter/parameter.reducer";
import { toggleParameterDialog } from "../context/parameter/parameter.actions";

const UserManagementSpecialActions = () => {
  const { dispatch } = useContext(userDataContext);
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="add-user"
        onClick={() => dispatch(toggleUserDialog())}
      >
        <AddCircleIcon />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="access"
        onClick={() => dispatch(toggleAccessDialog())}
      >
        <VpnKeyIcon />
      </IconButton>
    </>
  );
};

const ParameterManagementSpecialActions = () => {
  const { dispatch } = useContext(paramContext);
  return (
    <IconButton
      color="inherit"
      aria-label="add-param"
      onClick={() => dispatch(toggleParameterDialog())}
    >
      <AddCircleIcon />
    </IconButton>
  );
};

const SpecialModuleActions = ({ currentModule }) => {
  const renderActions = () => {
    switch (currentModule) {
      case "User Management":
        return <UserManagementSpecialActions />;
      case "Parameters":
        return <ParameterManagementSpecialActions />;
      default:
        return null;
    }
  };

  return <>{renderActions()}</>;
};

export default SpecialModuleActions;
