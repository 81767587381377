import { createContext } from "react";
import { useReducerWithThunk } from "../../hooks/hooks";
import UserActionTypes from "./user.types";

export const USER_INITIAL_STATE = {
  currentUser: {name: 'dwan', token: 'test', accesslevel: {name: 'admin'}},
  // currentUser: null,
  isAuthenticating: false,
  error: null,
};

const userReducer = (state = USER_INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_START:
      return {
        ...state,
        isAuthenticating: true,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticating: false,
        error: null,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        error: action.payload,
      };
    case UserActionTypes.SIGN_OUT:
      return {
        ...state,
        currentUser: null,
        error: null,
      };
    default:
      return state;
  }
};

const authContext = createContext(USER_INITIAL_STATE);

const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducerWithThunk(
    userReducer,
    USER_INITIAL_STATE
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AuthProvider, authContext, userReducer };
