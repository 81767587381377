const UserDataActionTypes = {
  FETCH_DATA_START: "FETCH_DATA_START",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  TOGGLE_USER_DIALOG: "TOGGLE_USER_DIALOG",
  TOGGLE_ACCESS_DIALOG: "TOGGLE_ACCESS_DIALOG",
  RESET_ERROR: "RESET_ERROR",
};

export default UserDataActionTypes;
